import React, { useContext, useEffect, useState } from "react";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { Layout } from "../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { theme } from "../theme/theme";
import { Ticker } from "../components/Ticker";

const Contact = ({ pageContext }) => {
  const { page } = pageContext.data;

  return (
    <>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Layout>
        <Main className="flex row container padding">
          <div className="First flex col">
            <div
              className="Contact content"
              dangerouslySetInnerHTML={{ __html: page.contact }}
            />
            <Ticker />
            <div className="MobilePicture">
              <GatsbyImage
                className="itemImg"
                alt={page.image?.alt || "Profile image"}
                image={page.image?.gatsbyImageData}
              />
            </div>
            <div
              className="Introduction content"
              dangerouslySetInnerHTML={{ __html: page.introduction }}
            />
            <p className="ClientsTitle">
              Selected clients Jacob has had the honor to work with
              independently or under different design agencies:
            </p>
            <div className="Clients flex">
              <div dangerouslySetInnerHTML={{ __html: page.clients }} />
              <div dangerouslySetInnerHTML={{ __html: page.clientsSecond }} />
            </div>
          </div>
          <div className="Second flex col">
            <GatsbyImage
              className="itemImg"
              alt={page.image?.alt || "Profile image"}
              image={page.image?.gatsbyImageData}
            />
          </div>
        </Main>
      </Layout>
    </>
  );
};

export default Contact;

const Main = styled.main`
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 60px;

  h1 {
    font-weight: 500;
    margin-bottom: 30px;
  }
  .container > div {
    flex: 1;
  }
  .content {
    padding-right: 95px;
    @media (max-width: 600px) {
      padding-right: 20px;
    }
  }
  .Contact {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    strong {
      font-size: 22px;
      font-weight: 600;
    }
  }
  .Introduction {
    font-size: 24px;
    line-height: 34px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding-top: 25px;
    padding-bottom: 15px;
    @media (max-width: 900px) {
      border-top: none;
    }
  }
  .ClientsTitle {
    max-width: 600px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 30px 0 25px;
  }
  .Clients {
    white-space: pre-line;
    font-size: 18px;
    line-height: 22px;
    > div {
      width: 50%;
    }
  }
  .MobilePicture {
    display: none;
    @media (max-width: 900px) {
      display: flex;
    }
  }
  .First {
    padding-right: 20px;
    width: 50%;

    @media (max-width: 900px) {
      padding-right: 0;
      width: 100%;
    }
    /* Override Ticker Styles */
    .padding {
      padding-left: 0;
      padding-right: 0;
    }
    .Ticker {
      @media (max-width: 900px) {
        display: none;
      }
    }
  }
  .Second {
    padding-top: 50px;
    width: 50%;
    @media (max-width: 900px) {
      display: none;
    }
  }
`;
